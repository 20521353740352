.i18n-markdown {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 1rem;
  }

  ol {
    margin-bottom: 1rem;
  }

  blockquote {
    margin-bottom: 1rem;
  }

  table {
    margin-bottom: 1rem;
  }

  pre {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  h6 {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  code {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
}
